import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

app.config.globalProperties.$filters = {
  capitalize(value: string): string {
    if (!value) return '';
    const tmp = value.toString();
    return tmp.charAt(0).toUpperCase() + tmp.slice(1);
  },
};

app.use(store);
app.use(router);
app.mount('#app');
