export default function methods(): { name: string, tagClass: string }[] {
  return [
    {
      name: 'Bruteforce',
      tagClass: 'is-success',
    },
    {
      name: 'Branch and Bound',
      tagClass: 'is-success',
    },
    {
      name: 'Dynamic Programming',
      tagClass: 'is-info',
    },
    {
      name: 'Greedy Programming',
      tagClass: 'is-info',
    },
    {
      name: 'Linear Programming',
      tagClass: 'is-danger',
    },
    {
      name: 'Constraints Programming',
      tagClass: 'is-danger',
    },
    {
      name: 'Randomized Hill Climbing',
      tagClass: 'is-warning',
    },
    {
      name: 'Simulated Annealing',
      tagClass: 'is-warning',
    },
    {
      name: 'Genetic Algorithm',
      tagClass: 'is-warning',
    },
    {
      name: 'Tabu Search',
      tagClass: 'is-warning',
    },
    {
      name: 'Ant Colony Optimization',
      tagClass: 'is-warning',
    },
    {
      name: 'Particle Swarm Optimization',
      tagClass: 'is-warning',
    },
    {
      name: 'Artificial Bee Colony',
      tagClass: 'is-warning',
    },
  ];
}
