
import { defineComponent } from 'vue';
import { mapMutations, mapState } from 'vuex';

import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import MessageModal from './components/common/MessageModal.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    FooterBar,
    MessageModal,
  },
  computed: {
    ...mapState(['showPocModal']),
  },
  methods: {
    ...mapMutations(['toggle_poc_modal']),
  },
});
