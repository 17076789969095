
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessageModal',
  data() {
    return {
      escListening: false,
    };
  },
  props: {
    isActive: Boolean,
    message: String,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    becomingActive() {
      const html = document.querySelector('html');

      if (html instanceof HTMLHtmlElement) {
        html.classList.add('is-clipped');
      }
      if (!this.escListening) {
        this.escListening = true;
        document.addEventListener('keyup', this.escListener);
      }
    },
    becomingInactive() {
      const html = document.querySelector('html');

      if (html instanceof HTMLHtmlElement) {
        html.classList.remove('is-clipped');
      }
      if (this.escListening) {
        this.escListening = false;
        document.removeEventListener('keyup', this.escListener);
      }
    },
    escListener(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.closeModal();
      }
    },
  },
  mounted() {
    if (this.isActive) {
      this.becomingActive();
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        this.becomingActive();
      } else {
        this.becomingInactive();
      }
    },
  },
  unmounted() {
    this.becomingInactive();
  },
});
