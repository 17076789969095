import { Problem } from '@/model/Problem';

function problemEmpty(): Problem {
  return {
    shortName: 'Empty',
    name: 'A New Problem',
    icon: 'plus',
    description: 'An empty description',
    singleEntities: [],
    multiEntities: [],
    groupEntities: [],
    linkEntities: [],
    parameters: [],
    indices: [],
    computed: [],
    variables: [],
    constraints: [],
    objective: {
      name: 'Undefined',
      description: 'Undefined',
      formula: '',
    },
  };
}

function problemKnapsack(): Problem {
  return {
    shortName: 'Knapsack',
    name: 'The Knapsack Problem',
    icon: 'suitcase',
    description: 'The knapsack problem is a combinatorial optimization problem in which a knapsack must be filled with a number of items. The total weight of the items packed into the knapsack must not exceed the capacity of the knapsack and the total value of the items packed into the knapsack must be maximized.',
    singleEntities: [],
    multiEntities: [
      {
        name: 'Item',
        description: 'An item to be packed into the knapsack',
        symbol: 'i',
        attributes: [
          {
            name: 'Weight',
            description: 'The weight of the item',
            symbol: '\\omega_i',
            type: 'Real Positive',
          },
          {
            name: 'Value',
            description: 'The value of the item',
            symbol: 'v_i',
            type: 'Real Positive',
          },
        ],
      },
    ],
    groupEntities: [],
    linkEntities: [],
    parameters: [
      {
        name: 'Capacity',
        description: 'The maximum weight the knapsack can hold',
        symbol: 'C',
        type: 'Real Positive',
      },
    ],
    indices: [],
    computed: [],
    variables: [
      {
        name: 'Assignment',
        description: 'Whether the item is packed into the knapsack',
        symbol: 'x_i',
        type: 'Binary',
      },
    ],
    constraints: [
      {
        name: 'Capacity Constraint',
        description: 'The total weight of the items packed into the knapsack must not exceed the capacity of the knapsack',
        overWhich: [],
        formula: '\\sum_{i} \\omega_i x_i \\leq C',
      },
    ],
    objective: {
      name: 'Maximize Value',
      description: 'The total value of the items packed into the knapsack must be maximized',
      formula: '\\sum_{i} v_i x_i',
    },
  };
}

function problemTSP(): Problem {
  return {
    shortName: 'TSP',
    name: 'The Travelling Salesman Problem',
    icon: 'globe',
    description: 'The travelling salesman problem is a combinatorial optimization problem in which a salesman must visit a number of cities and return to the starting city. The salesman must visit each city exactly once and the total distance travelled must be minimized.',
    singleEntities: [],
    multiEntities: [
      {
        name: 'City',
        description: 'A city to be visited by the salesman',
        symbol: 'i\\text{ or }j',
        attributes: [],
      },
    ],
    groupEntities: [],
    linkEntities: [
      {
        name: 'Route',
        description: 'A route between two cities',
        symbol: 'ij',
        entityNames: ['City', 'City'],
        attributes: [
          {
            name: 'Distance',
            description: 'The distance between two cities',
            symbol: 'd_{ij}',
            type: 'Real Positive',
          },
        ],
      },
    ],
    computed: [],
    indices: [],
    parameters: [],
    variables: [
      {
        name: 'Assignment',
        description: 'Whether the route is taken by the salesman',
        symbol: 'x_{ij}',
        type: 'Binary',
      },
    ],
    constraints: [
      {
        name: 'One Ingoing Edge',
        description: 'Each city must have exactly one ingoing edge',
        overWhich: ['j'],
        formula: '\\sum_{i,i \\neq j} x_{ij} = 1',
      },
      {
        name: 'One Outgoing Edge',
        description: 'Each city must have exactly one outgoing edge',
        overWhich: ['i'],
        formula: '\\sum_{j,j \\neq i} x_{ij} = 1',
      },
      {
        name: 'No self-loops',
        description: 'There must be no edge between a city and itself',
        overWhich: ['i'],
        formula: 'x_{ii} = 0',
      },
    ],
    objective: {
      name: 'Minimize Distance',
      description: 'The total distance of the routes taken by the salesman must be minimized',
      formula: '\\sum_{i,j} d_{ij} x_{ij}',
    },
  };
}

function problemLogistic(): Problem {
  return {
    shortName: 'MT-CVRP',
    name: 'A Multi-Tour CVRP Problem',
    icon: 'truck',
    description: 'This logistic problem is a combinatorial optimization problem in which a logistic company must deliver a number of products to a number of retailers. The logistic company must deliver products according to an order book and the total distance travelled by the delivering truck must be minimized. <br><br>Moreover, the truck has a limited capacity, and, because we want to satisfy the demand of all the retailers, the truck must go back to the warehouse to load yet-undelivered products, which makes this problem a multi-tour problem. <br><br> CVRP means Capacited Vehicule Routing Problem.',
    singleEntities: [
      {
        name: 'Warehouse',
        description: 'A warehouse where all products are at the beginning, and where the truck must start and end the tours',
        symbol: 'w',
        attributes: [],
      },
    ],
    multiEntities: [
      {
        name: 'Product',
        description: 'A product to be delivered to a retailer',
        symbol: 'p',
        attributes: [],
      },
      {
        name: 'Retailer',
        description: 'A retailer to deliver the product',
        symbol: 'r',
        attributes: [],
      },
    ],
    groupEntities: [
      {
        name: 'Site',
        description: 'A site that is either the warehouse or a retailer',
        symbol: 's',
        entityNames: ['Warehouse', 'Retailer'],
        attributes: [],
      },
    ],
    linkEntities: [
      {
        name: 'Route',
        description: 'Every route between two sites (warehouse and retailers)',
        symbol: '',
        entityNames: ['Site', 'Site'],
        attributes: [
          {
            name: 'Distance',
            description: 'The distance between two sites (two retailers, or the warehouse with one retailer)',
            symbol: 'd_{s_1s_2}',
            type: 'Real Positive',
          },
        ],
      },
      {
        name: 'OrderBook',
        description: 'The book (list) of orders',
        symbol: '',
        entityNames: ['Product', 'Retailer'],
        attributes: [
          {
            name: 'Order',
            description: 'The number of product p to be delivered to retailer r',
            symbol: 'q_{pr}',
            type: 'Integer Positive',
          },
        ],
      },
    ],
    parameters: [
      {
        name: 'Capacity',
        description: 'The maximum number of products the truck can load at once',
        symbol: 'C',
        type: 'Integer Positive',
      },
      {
        name: 'MaxTour',
        description: 'The maximum number of tours the truck can make',
        symbol: 'T',
        type: 'Integer Positive',
      },
    ],
    indices: [
      {
        name: 'Tour',
        description: 'The tour number',
        formula: '1 \\leq t \\leq T',
        type: 'Integer Positive',
      },
    ],
    computed: [
      {
        name: 'NumberProducts',
        description: 'The total number of products to be delivered',
        formula: 'P = \\sum_{p,r} q_{pr}',
        type: 'Integer Positive',
      },
      {
        name: 'NumberRetailers',
        description: 'The total number of retailers to be delivered',
        formula: 'R = |\\text{retailers}|',
        type: 'Integer Positive',
      },
      {
        name: 'NumberSites',
        description: 'The total number of sites',
        formula: 'S = 1 + R',
        type: 'Integer Positive',
      },
    ],
    variables: [
      {
        name: 'Delivering',
        description: 'Whether a truck is delivering on tour t',
        symbol: '\\phi_{t}',
        type: 'Binary',
      },
      {
        name: 'SitePassed',
        description: 'Whether a site will be passed on tour t',
        symbol: '\\theta_{st}',
        type: 'Binary',
      },
      {
        name: 'Route',
        description: 'Whether a route is taken by the truck on tour t',
        symbol: '\\pi_{s_1s_2t}',
        type: 'Binary',
      },
      {
        name: 'Demand',
        description: 'The number of remaining products that needs delivering to retailer r at the end of tour t',
        symbol: 'k_{prt}',
        type: 'Integer Positive or Zero',
      },
      {
        name: 'Delivery',
        description: 'The number of products p delivered to retailer r on tour t',
        symbol: 'x_{prt}',
        type: 'Integer Positive or Zero',
      },
    ],
    constraints: [
      {
        name: 'Delivering and Retailer Delivered Consistency',
        description: 'If a truck is not delivering, no retailer will be delivered. Also, if no retailers are delivered, the truck is not necessarily delivering',
        overWhich: ['t'],
        formula: '\\sum_{s} \\theta_{st} \\leq S \\times \\phi_{t}',
      },
      {
        name: 'Delivering tour must go through the warehouse',
        description: 'If a truck is delivering, it must go start and finish by the warehouse',
        overWhich: ['t'],
        formula: '\\theta_{wt} = \\phi_{t}',
      },
      {
        name: 'One ingoing edge',
        description: 'If a site is passed by the truck, the truck must have exactly one other site to go through after',
        overWhich: ['t', 's_2'],
        formula: '\\theta_{s_2t} = \\sum_{s_1} \\pi_{s_1s_2t}',
      },
      {
        name: 'One outgoing edge',
        description: 'If a site is passed by the truck, the truck must have exactly one other site that it must come from',
        overWhich: ['t', 's_1'],
        formula: '\\theta_{s_1t} = \\sum_{s_2} \\pi_{s_1s_2t}',
      },
      {
        name: 'No self loops',
        description: 'If a site is passed by the truck, the truck must not go back to the same site',
        overWhich: ['t', 's'],
        formula: '\\pi_{sst} = 0',
      },
      {
        name: 'Every Product Delivered',
        description: 'Every ordered products must be delivered at the end of tours',
        overWhich: ['p', 'r'],
        formula: 'k_{prT} = 0',
      },
      {
        name: 'Delivery and SitePassed Consistency',
        description: 'Delivery must respect whether the site has been passed',
        overWhich: ['t', 'r'],
        formula: '\\sum_{p} x_{prt} \\leq P \\times \\theta_{rt}',
      },
      {
        name: 'Demand and Delivery Consistency Init',
        description: 'The demand at the beginning of the tours must be consistent with the initial demand and the delivery of the first tour',
        overWhich: ['p', 'r'],
        formula: 'k_{pr1} = q_{pr} - x_{pr1}',
      },
      {
        name: 'Demand and Delivery Consistency Rec',
        description: 'The demand at tour t must be consistent with the previous tour and the current delivery',
        overWhich: ['p', 'r', 't \\geq 2'],
        formula: 'k_{prt} = k_{pr(t - 1)} - x_{prt}',
      },
      {
        name: 'Delivery and Capacity Consistency',
        description: 'The delivery must respect the capacity of the truck',
        overWhich: ['t'],
        formula: '\\sum_{p,r} x_{prt} \\leq C',
      },
    ],
    objective: {
      name: 'Minimize Distance',
      description: 'The total distance of the routes taken by the logistic company must be minimized',
      formula: '\\sum_{t,s_1,s_2} d_{s_1s_2} \\pi_{s_1s_2t}',
    },
  };
}

export function problemFactory(): { default: Problem, factory: { [key: string]: Problem } } {
  return {
    default: problemEmpty(),
    factory: {
      knapsack: problemKnapsack(),
      tsp: problemTSP(),
      logistic: problemLogistic(),
    },
  };
}

export default {
  problemFactory,
};
