
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';

export default defineComponent({
  name: 'NavBar',
  methods: {
    ...mapMutations(['toggle_poc_modal', 'set_show_poc_extended']),
  },
  computed: {
    ...mapState(['showPocExtended']),

    switchLabel() {
      return this.showPocExtended ? 'Extended POC' : 'Simple POC';
    },
  },
});
