import { createStore } from 'vuex';
import { problemFactory } from '@/model/problemFactory';
import { type Problem } from '@/model/Problem';
import randomDate from '@/helpers/random/randomDate';
import methods from '@/model/methods';
import randomInt from '@/helpers/random/randomInt';

type Dataset = {
  name: string,
  size: string,
  createdAt: string,
  updatedAt: string,
}

function datasets(): Dataset[] {
  const res = [];

  for (let i = 0; i < 8; i += 1) {
    const size = 32 + Math.floor(Math.random() * 64);

    res.push({
      name: `Dataset ${i + 1}`,
      size: `${size} MB`,
      createdAt: randomDate(2018, 2020),
      updatedAt: randomDate(2020, 2023),
    });
  }
  return res;
}

type Experiment = {
  startTime: string,
  runTime: string,
  problemVersion: string,
  status: string,
  datasetUsed: string,
  methodUsed: string,
  methodTagClass: string,
}

function experiments(): Experiment[] {
  const res = [];
  const loadedMethods = methods();
  const indexMethod = [];

  const nbRuns = loadedMethods.length * 2;

  for (let i = 0; i < loadedMethods.length; i += 1) {
    indexMethod.push(i);
  }

  while (indexMethod.length < nbRuns) {
    indexMethod.push(randomInt(0, loadedMethods.length - 1));
  }

  for (let i = 0; i < nbRuns; i += 1) {
    res.push({
      startTime: randomDate(2020, 2021),
      runTime: `${1 + Math.floor(Math.random() * 30)} min`,
      problemVersion: 'Latest',
      status: 'Completed',
      datasetUsed: `Dataset ${Math.floor(Math.random() * 8) + 1}`,
      methodUsed: loadedMethods[indexMethod[i]].name,
      methodTagClass: loadedMethods[indexMethod[i]].tagClass,
    });
  }

  res.sort((a, b) => {
    if (a.startTime > b.startTime) return 1;
    if (a.startTime < b.startTime) return -1;
    return 0;
  });

  return res;
}

type State = {
  route: string,
  showPocModal: boolean,
  problem: Problem | null,
  datasets: Dataset[],
  experiments: Experiment[],
  showPocExtended: boolean,
}

export default createStore<State>({
  state: {
    route: 'home',
    showPocModal: false,
    problem: null,
    datasets: datasets(),
    experiments: experiments(),
    showPocExtended: false,
  },
  getters: {
    isRouteHome(state) {
      return state.route === 'home';
    },
    isRouteDemo(state) {
      return state.route === 'demo';
    },
    problemShortName(state) {
      if (state.problem == null) return null;
      return state.problem.shortName;
    },
  },
  mutations: {
    set_route(state, route) {
      if (route == null) return;
      if (!['home', 'demo'].includes(route)) return;

      state.route = route;
    },
    toggle_poc_modal(state) {
      state.showPocModal = !state.showPocModal;
    },
    reset_problem(state) {
      state.problem = problemFactory().default;
    },
    set_problem(state, problem) {
      state.problem = JSON.parse(JSON.stringify(problem));
    },
    set_show_poc_extended(state, showPocExtended) {
      if (showPocExtended == null) return;
      if (showPocExtended.target == null) return;

      state.showPocExtended = showPocExtended.target.checked;
    },
  },
  actions: {
  },
  modules: {
  },
});
